
import { computed, defineComponent, WritableComputedRef } from 'vue';
import { Form } from 'vee-validate';
import BaseInput from '@/components/base/BaseInput.vue';
import BasePasswordInput from '@/components/base/BasePasswordInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import GoogleAuthButton from '@/components/GoogleAuthButton.vue';

const SignUpForm = defineComponent({
  components: {
    BaseInput,
    BasePasswordInput,
    BaseButton,
    Form,
    GoogleAuthButton,
  },

  emits: ['update:email', 'update:password', 'submit'],

  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    loading: Boolean,
    error: Boolean,
  },

  setup(props, { emit }) {
    const validationSchema = {
      email: 'required|email',
      password: 'required|min:8',
    };

    const localEmail: WritableComputedRef<string> = computed({
      get: (): string => props.email,
      set: (value: string): void => emit('update:email', value),
    });

    const localPassword: WritableComputedRef<string> = computed({
      get: (): string => props.password,
      set: (value: string): void => emit('update:password', value),
    });

    return { localEmail, localPassword, validationSchema };
  },
});

export default SignUpForm;
