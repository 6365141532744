
import {
  defineComponent, ref, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import BaseCard from '@/components/base/BaseCard';
import SignInForm from '@/components/forms/SignInForm.vue';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import useAuth from '@/mixins/useAuth';
import RequestStatus from '@/constants/RequestStatus';
import useSignIn from '@/mixins/useSignIn';
import * as Sentry from '@sentry/vue';

const TheSignIn = defineComponent({
  components: { BaseCard, SignInForm, BaseAlertMessage },

  setup() {
    const { signIn } = useAuth();
    const { handleSignIn } = useSignIn();
    const router = useRouter();

    const email = ref<string>('');
    const password = ref<string>('');
    const errorCode = ref<string>('');
    const status = ref<RequestStatus>(RequestStatus.Initial);

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const onSubmit = async (): Promise<void> => {
      status.value = RequestStatus.Loading;

      const response = await signIn(email.value, password.value);

      if (!response.imgsId) {
        status.value = RequestStatus.Error;
        errorCode.value = response;
        Sentry.captureException(new Error(`User ${email.value} not found during sin in`));

        return;
      }

      const result = await handleSignIn(response);

      if (!result) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Unable to sign in'));

        return;
      }

      status.value = RequestStatus.Success;
      router.push({ name: 'dashboard' });
    };

    return {
      isLoading,
      isError,
      email,
      password,
      onSubmit,
      errorCode,
    };
  },
});

export default TheSignIn;
